import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import BackgroundAnimation from '../components/BackgroundAnimation'

export const IndexProjectPageTemplate = ({
  content,
  contentComponent,
  title,
  mainpitch,
}) => {
  const PostContent = contentComponent || Content

return (
<div id="projectIndex">
    <div
      className="full-width-image margin-top-0">
      <div
        style={{
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen page-header-title"
        >
          {title}
        </h1>
      </div>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="columns">
                  <div className="column is-12">
                    <div className="tile">
                      <h1 className="has-text-weight-semibold">
                        {mainpitch.title}
                      </h1>
                    </div>
                    <p style={{whiteSpace: `pre-line`}}>{mainpitch.description}</p>
                  </div>
                </div>
                <PostContent content={content} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <BackgroundAnimation></BackgroundAnimation>
  </div>
)
              }

IndexProjectPageTemplate.propTypes = {
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  mainpitch: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <IndexProjectPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        image={post.frontmatter.image}
        title={post.frontmatter.title}
        mainpitch={post.frontmatter.mainpitch}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexProjectPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-project-page" } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        mainpitch {
          title
          description
        }
        description
      }
    }
  }
`
